module.exports = {
  content: ['./src/**/*.{js,jsx}'],
  theme: {
    extend: {
      colors: {
        'light-gray': '#F5F6F7',
        'link-gray': '#808080',
      },
      spacing: {
        '1%': '1%',
        '1/10': '10%',
        '2/10': '20%',
        '9/10': '90%',
        '2px': '2px',
        '6px': '6px',
        '8px': '8px',
        '12px': '12px',
        '14px': '14px',
        '16px': '16px',
        '20px': '20px',
        '24px': '24px',
        '32px': '32px',
        '36px': '36px',
        '40px': '40px',
        '42px': '42px',
        '48px': '48px',
        '56px': '56px',
        '64px': '64px',
        '72px': '72px',
        '86px': '86px',
        '96px': '96px',
        '112px': '112px',
        '120px': '120px',
        '7.5vw': '7.5vw',
      },
      padding: {
        '1/10': '10%',
        '8px': '8px',
        '24px': '24px',
        '32px': '32px',
        '44px': '44px',
        '64px': '64px',
      },
      width: {
        '70%': '70%',
        '7/10': '70%',
      },
      height: {
        '20vh': '20vh',
        '35vh': '35vh',
        '40vh': '40vh',
        '60vh': '60vh',
        '70vh': '70vh',
        '80vh': '80vh',
      },
      maxHeight: {
        '40vh': '40vh',
        '80vh': '80vh',
      },
      lineHeight: {
        'extra-loose': '2.5',
      },
      zIndex: {
        1: '1',
        header: '1000',
        overlay: '2000',
        sidepanel: '2100',
        modal: '2100',
      },
    },
    screens: {
      xs: { max: '767px' },
      sm: { min: '768px', max: '979px' },
      md: { min: '980px', max: '1279px' },
      lg: { min: '1280px' },
    },
    fontFamily: {
      noto: ['Noto Sans JP'],
    },
    fontSize: {
      9: '0.9rem',
      10: '1.0rem',
      11: '1.1rem',
      12: '1.2rem',
      13: '1.3rem',
      14: '1.4rem',
      15: '1.5rem',
      16: '1.6rem',
      18: '1.8rem',
      20: '2.0rem',
      22: '2.2rem',
      24: '2.4rem',
      26: '2.6rem',
      28: '2.8rem',
      32: '3.2rem',
      36: '3.6rem',
      40: '4.0rem',
      48: '4.8rem',
      52: '5.2rem',
      56: '5.6rem',
      60: '6.0rem',
      80: '8.0rem',
      100: '10.0rem',
      110: '11.0rem',
      120: '12.0rem',
      150: '15.0rem',
    },
  },
  plugins: [],
}
