import React from 'react'
import '~/styles/container.scss'
import Header from '~/components/nav/header'
import Footer from '~/components/nav/footer'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="main">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
