import { useEffect, useState } from 'react'

const useWindowSize = () => {
  //ハンバーガーメニューがちらつくので、デフォルトはPCサイズにしておく
  const [windowSize, setWindowSize] = useState(999)
  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
export default useWindowSize
