import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { gnav, gnavUl } from './nav.module.scss'
import SpGlobalNav from './spGlobalNav'
import useWindowSize from '../../utils/useWindowSize'

const GlobalNav = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulHeaderNav {
          nav {
            link
            text
            id
          }
        }
      }
    `
  )
  const navlinks = data.contentfulHeaderNav.nav
  return (
    <>
      {useWindowSize() < 768 ? (
        <SpGlobalNav />
      ) : (
        <nav className={gnav}>
          <ul className={gnavUl}>
            {navlinks.map(({ link, text, id }) => (
              <li key={id}>
                <Link to={link} key={id}>
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </>
  )
}
export default GlobalNav
