import React, { useState } from 'react'
import { Link } from 'gatsby'
import * as s from './spNav.module.scss'
import { useEffect } from 'react'

const SpGlobalNav = () => {
  //toggleメニューの高さと位置を調整
  useEffect(() => {
    const vh = window.innerHeight * 0.01

    const header = document.querySelector('#header')
    const headerH = header.clientHeight

    const spMenu = document.querySelector('#spToggleMenu')
    const spMenuH = 100 * vh - headerH
    spMenu.style.height = `${spMenuH}px`
    spMenu.style.top = `calc(${headerH}px / 2)`
  }, [])

  //menuが開いている間は背景のスクロールを禁止
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    document.body.style = isOpen ? `overflow: hidden` : `overflow: scroll`
  }, [isOpen])

  return (
    <nav className={s.spGnav}>
      <div className={isOpen ? `${s.hamburger} ${s.menuCross}` : `${s.hamburger}`} role="button" aria-label="hamburger menu" tabIndex={0} onClick={() => setIsOpen(!isOpen)} onKeyDown={() => setIsOpen(!isOpen)} />
      <ul id="spToggleMenu" className={isOpen ? `${s.spGnavUl}` : `${s.spGnavUl} ${s.spGnavUlClose}`}>
        <li>
          <Link to="/about">About Us</Link>
        </li>
        <li>
          <Link to="/news">News</Link>
        </li>
        <li>
          <Link to="/recruit">Recruit</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  )
}
export default SpGlobalNav
