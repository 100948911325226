import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { footerSub, gnav } from './nav.module.scss'

export default function Footer() {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulSns {
          sns {
            id
            link
            text
          }
        }
      }
    `
  )
  const links = data.contentfulSns.sns

  return (
    <footer className={footerSub}>
      <nav className={gnav}>
        <ul className="flex justify-center flex-wrap">
          {links.map(({ id, link, text }) => (
            <li key={id}>
              <a href={link} target="_blank" rel="noopener noreferrer" key={id}>
                {text}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  )
}
