import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import GlobalNav from './globalNav'
import useWindowSize from '../../utils/useWindowSize'
import { theme } from '../../../tailwind.config'

export default function Header() {
  const bp = parseInt(theme.screens.xs.max?.slice(0, -2))

  return (
    <header id="header" className="relative pt-10 z-header w-full xs:py-3 xs:sticky xs:top-0 xs:left-0 xs:bg-white">
      <h1 className="flex justify-center">
        <Link to="/" className="inline-block mx-auto">
          {useWindowSize() > bp ? (
            <StaticImage src="../../images/acg_logo_1.png" alt="株式会社アンティークコインギャラリア" objectFit="contain" loading="eager" placeholder="none" className="max-w-[160px]" />
          ) : (
            <StaticImage src="../../images/acg_logo_2.png" alt="株式会社アンティークコインギャラリア" objectFit="contain" loading="eager" placeholder="none" className="max-w-[100px]" />
          )}
        </Link>
      </h1>
      <GlobalNav />
    </header>
  )
}
